<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>조회 날짜</label>
            <div>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="changeStartDate($event)"
              />
              <p>~</p>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="changeEndDate($event)"
              />
            </div>
          </div>
          <button class="btn_sub2" @click="searchLog()">
            검색
          </button>
        </div>
        <div>
          <button class="excel_btn" @click="exportExcel()">
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ filteredPackageDayLog.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="7">
                포장기 현황
              </th>
            </tr>
            <tr>
              <th>조회시간</th>
              <th>포장기</th>
              <th>생산제품</th>
              <th>생산수량</th>
              <th>생산시간</th>
              <th colspan="2">비고</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in filteredPackageDayLog" :key="item.id">
              <td>{{ item.create_time.substr(0, 10) }}</td>
              <td>{{ item.plc_sensor_name.split('인터페이스')[0] }}</td>
              <td>{{ asciiChange(item.product_name) }}</td>
              <td>{{ $makeComma(item.count_value) }} EA</td>
              <td>{{ productTime(item.production_time) }}</td>
              <td>
                <input
                  type="text"
                  :value="item.detail"
                  @input="$event => (item.detail = $event.target.value)"
                />
              </td>
              <td>
                <button type="button" class="btn_tbl" @click="submitRow(item)">
                  수정
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import XLSX from 'xlsx';

export default {
  mixins: [FETCH_MIXIN],
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_logs_package_day: 'getPlcSensorLogPackageDay',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      plc_sensors: 'getPlcSensor',
      start_date: 'getStartDateFromPlcSensorLog',
      end_date: 'getEndDateFromPlcSensorLog',
    }),
    filteredPackageDayLog() {
      if (this.plc_sensor_logs_package_day.length > 0) {
        let clone = this.lodash.clonedeep(this.plc_sensor_logs_package_day);
        return clone.sort(
          (a, b) => new Date(b.create_time) - new Date(a.create_time),
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    exportExcel() {
      let powerList = [];
      const date = this.start_date + '~' + this.end_date;
      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
      ];

      powerList = powerList.concat(standardInfo);

      powerList.push(['포장기 사용현황']);
      powerList.push([
        '조회시간',
        '포장기',
        '생산제품',
        '생산수량',
        '생산시간',
        '비고',
      ]);

      this.filteredPackageDayLog.forEach(el => {
        powerList.push([
          el.create_time.substr(0, 10),
          el.plc_sensor_name.split('인터페이스')[0],
          this.asciiChange(el.product_name),
          `${this.$makeComma(el.count_value)} EA`,
          this.productTime(el.production_time),
          el.detail,
        ]);
      });

      let wb = XLSX.utils.book_new();

      let dataPower = XLSX.utils.json_to_sheet(powerList, { skipHeader: true });

      dataPower['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataPower, '포장기 사용현황');

      let title = '포장기 사용현황_' + '(' + date + ').xls';

      XLSX.writeFile(wb, title);
    },
    async searchLog() {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_PACKAGE_LOG_DAY',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '포장기 현황',
      );
      await this.FETCH('FETCH_PLC_SENSOR_LOG_NOW', '센서 현재값');
    },
    changeStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          // this.start_date = e.target.value;
          this.$store.commit('setStartDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },
    changeEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          // this.end_date = e.target.value;
          this.$store.commit('setEndDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },
    asciiChange(item) {
      let temp = item.replace(/00/gi, '').split('');
      let arr = [];
      temp.map((x, index) => {
        index % 2 == 0
          ? (arr += String.fromCharCode(
              parseInt(temp.slice(index, index + 2).join(''), 16),
            ))
          : '';
      });
      return arr;
    },
    productTime(item) {
      return item
        .split(':')
        .map(x => (String(x).length < 2 ? (x = `0${x}`) : x))
        .join(':');
    },
    submitRow(item) {
      this.showSpinner();
      const payload = this.lodash.clonedeep(item);
      this.$store
        .dispatch('UPDATE_PLC_SENSOR_PACKAGE_LOG', {
          id: payload.id,
          detail: payload.detail,
        })
        .then(() => {
          this.openOneButtonModal('수정 완료', '수정되었습니다.');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('등록 실패', '포장기 현황 수정 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(date2));
    }
    await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
  },
};
</script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {
  Swiper as SwiperClass,
  Pagination,
  Autoplay,
  Navigation,
} from 'swiper';

SwiperClass.use([Pagination, Autoplay, Navigation]);
import 'swiper/swiper-bundle.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      playStop: true,
      key: 0,
      swiperOption: {
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        centeredSlides: true,
        slidePerView: 'auto',
        loop: false,
        watchOverflow: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev',
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiperRef.$swiper;
    },
  },
  methods: {
    fullScreenFn() {
      this.fullScreen = !this.fullScreen;
      this.$nextTick(() => {
        if (this.swiper.slides.length > 3) {
          let navigation = document.querySelector('.slide_btn_wrap');
          this.swiper.autoplay.start();
          this.playStop = true;
          this.swiper.allowTouchMove = true;
          navigation.style.display = 'flex';
        }
      });
    },
    autoPlay() {
      if (this.swiper.autoplay.running) {
        this.swiper.autoplay.stop();
        this.playStop = false;
      } else {
        this.swiper.autoplay.start();
        this.playStop = true;
      }
    },
  },
  // updated() {
  //   this.$nextTick(() => {
  //     if (
  //       this.swiper != undefined &&
  //       this.swiper.slides.length <= 3 &&
  //       this.swiper.slides.length > 0
  //     ) {
  //       let navigation = document.querySelector('.slide_btn_wrap');
  //       this.swiper.autoplay.stop();
  //       this.playStop = false;
  //       this.swiper.allowTouchMove = false;
  //       navigation.style.display = 'none';
  //     }
  //   });
  // },
  destroyed() {
    window.removeEventListener(
      'keydown',
      (document.onkeydown = e => {
        e = e || window.event;
        if (e.keyCode == 27) {
          if (this.fullScreen) {
            this.fullScreen = false;
          }
        }
      }),
    );
  },
  created() {
    window.addEventListener(
      'keydown',
      (document.onkeydown = e => {
        e = e || window.event;
        if (e.keyCode == 27) {
          if (this.fullScreen) {
            this.fullScreen = false;
          }
        }
      }),
    );
  },
};

<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>전력 사용현황</h2>
          <span>조회시간 : {{ str_time }}</span>
        </div>
        <div class="monitor_body">
          <div class="day_card">
            <h2 class="curr_time">
              {{ curr_date }}
              <br />{{ curr_weekDay }}
              <span>{{ curr_time }}</span>
            </h2>
          </div>
          <div class="power_card">
            <h2>
              {{
                filteredPlcDayStack
                  .map(x => x.today_value)
                  .reduce((a, b) => $decimalAdd(a, b), 0)
              }}
              <span>kwh</span>
            </h2>
            <ul>
              <li>
                <span>누적 사용량</span>
                <p>
                  {{
                    filteredPlcDayStack
                      .map(x => x.thismonth_value)
                      .reduce((a, b) => $decimalAdd(a, b), 0)
                  }}
                  <span>kwh</span>
                </p>
              </li>
              <li>
                <span>전월 대비</span>
                <p
                  :class="{
                    infi: filteredPlcMonthStack == 'Infinity',
                    down: filteredPlcMonthStack < 100,
                    stay: filteredPlcMonthStack == 100,
                  }"
                >
                  {{
                    filteredPlcMonthStack == 'Infinity'
                      ? 'N/A'
                      : filteredPlcMonthStack.toFixed(2)
                  }}{{ filteredPlcMonthStack != 'Infinity' ? '%' : '' }}
                </p>
              </li>
            </ul>
          </div>
          <div
            class="monitor_card"
            v-for="item in filteredPlcDayStack"
            :key="item.id"
          >
            <h6>
              {{
                findInfoFromId(
                  plc_locations,
                  plc_sensors.find(x => x.id == item.plc_sensor_id)
                    .plc_location_id,
                ).name
              }}{{
                findInfoFromId(
                  plc_locations,
                  plc_sensors.find(x => x.id == item.plc_sensor_id)
                    .plc_location_id,
                ).name != '포장실'
                  ? `(${plc_sensors
                      .find(x => x.id == item.plc_sensor_id)
                      .name.substr(0, 2)})`
                  : ''
              }}
            </h6>
            <p>{{ item.today_value }} <span>kwh</span></p>
            <div>
              월 누적 사용량
              <p>{{ item.thismonth_value }} <span>kwh</span></p>
            </div>
          </div>
          <div class="monitor_card">
            <bar-chart
              :width="$screen.width > 1280 ? 300 : 240"
              :height="$screen.width > 1280 ? 180 : 120"
              :options="chartData.options"
              :data="chartData.data"
              :key="key"
            ></bar-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import BarChart from '@/layouts/components/charts/CustomBarChart';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  components: { BarChart },
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      // plc_sensor_logs: 'getPlcSensorLog',
      plc_sensors: 'getPlcSensor',
      plc_locations: 'getSensorLocations',
      plc_sensor_logs_day_stack: 'getPlcSensorLogDayStack',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    filteredPlcDayStack() {
      if (this.plc_sensor_logs_day_stack.length > 0) {
        let clone = this.lodash
          .clonedeep(this.plc_sensor_logs_day_stack)
          .filter(x => [12, 13, 14, 15, 16, 17, 18].includes(x.plc_sensor_id))
          .sort((a, b) => b.id - a.id);
        return clone;
      } else {
        return [];
      }
    },
    filteredPlcMonthStack() {
      if (
        this.plc_sensor_logs_day_stack.length > 0 &&
        this.filteredPlcDayStack.length > 0
      ) {
        let value =
          (this.filteredPlcDayStack
            .map(x => x.thismonth_value)
            .reduce((a, b) => this.$decimalAdd(a, b), 0) /
            this.filteredPlcDayStack
              .map(x => x.lastmonth_value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0)) *
          100;
        return value;
      } else {
        return 0;
      }
    },
    chartDataStack() {
      if (this.filteredPlcDayStack.length > 0) {
        let sortStack = this.lodash
          .clonedeep(this.filteredPlcDayStack)
          .sort((a, b) => b.thismonth_value - a.thismonth_value);
        let sum = sortStack
          .map(x => x.thismonth_value)
          .reduce((a, b) => this.$decimalAdd(a, b), 0);
        let value = sortStack.map(x =>
          this.$decimalMul(
            this.$decimalDiv(x.thismonth_value, sum),
            100,
          ).toFixed(2),
        );
        return value;
      } else {
        return [];
      }
    },
    chartLabelStack() {
      if (this.filteredPlcDayStack.length > 0) {
        let sortStack = this.lodash
          .clonedeep(this.filteredPlcDayStack)
          .sort((a, b) => b.thismonth_value - a.thismonth_value);
        let value = sortStack.map(x => {
          const name = this.plc_sensors.find(y => y.id == x.plc_sensor_id).name;
          // const name = this.plc_sensor_logs.find(
          //   y => y.plc_sensor_id == x.plc_sensor_id,
          // ).plc_sensor_name;

          if (name.includes('전체')) {
            if (name.includes('정맥')) {
              return '정전';
            } else {
              return '백전';
            }
          } else {
            return name.substr(0, 2);
          }
        });
        return value;
      } else {
        return [];
      }
    },
    chartData() {
      let label = this.chartLabelStack;
      let data = {
        labels: label,
        datasets: [
          {
            data: this.chartDataStack,
            fill: false,
            pointRadius: 4,
            backgroundColor: [
              '#7ca4d9',
              '#fdfdfd',
              '#6f7fef',
              '#456edd',
              '#fdfdfd',
              '#69ccb5',
              '#4fa1e8',
            ],
            borderWidth: 0,
          },
        ],
      };

      let options = {
        legend: {
          display: false,
          position: 'top',
          labels: {
            fontColor: '#ffffff',
            fontSize: 14,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#ffffff',
                fontSize: 14,
                lineWidth: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#ffffff',
                fontSize: 14,
                lineWidth: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return;
            tooltip.displayColors = false;
          },
          enabled: true,
        },
      };

      return { data: data, options: options };
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER(
        'FETCH_PLC_SENSOR_LOG_DAY_STACK',
        '전력 사용현황',
      );
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH('FETCH_PLC_SENSOR_LOG_DAY_STACK', '전력 사용현황');
    if (this.plc_locations.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_LOCATION', '센서 위치');
    }
    await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER(
          'FETCH_PLC_SENSOR_LOG_DAY_STACK',
          '전력 사용현황',
        );
        this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    this.key++;
  },
};
</script>

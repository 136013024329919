<script>
export function makeComma(value) {
  if (value == undefined) return '';
  value = String(value).replace(/,/gi, '');
  if (value.trim().length == 0) {
    return '0';
  }
  let returnValue = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (returnValue.includes('.')) {
    returnValue =
      returnValue.split('.')[0] +
      '.' +
      returnValue.split('.')[1].replace(/,/gi, '');
  }
  return returnValue;
}
const horizonalLinePlugin = {
  id: 'horizontalLine',
  afterDraw: chartInstance => {
    var yValue;
    var yScale = chartInstance.scales['y-axis-0'];
    var canvas = chartInstance.chart;
    var ctx = canvas.ctx;
    var index;
    var line;
    var style;
    chartInstance.data.datasets.forEach((dataset, index) => {
      let meta = chartInstance.getDatasetMeta(index);
      if (!meta.hidden) {
        index == 1
          ? dataset.label == '습도'
            ? (ctx.fillStyle = '#5984d9')
            : (ctx.fillStyle = '#ff1e1e')
          : dataset.label == '온도'
          ? (ctx.fillStyle = '#ef6262')
          : (ctx.fillStyle = '#004a89');
        meta.data.forEach((element, index1) => {
          ctx.font = Chart.helpers.fontString(13, 'normal');
          let dataString = makeComma(dataset.data[index1]);
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let padding = 5;
          let position = element.tooltipPosition();
          index == 1
            ? ctx.fillText(
                dataString,
                position.x,
                position.y - 13 / 2 - padding,
              )
            : ctx.fillText(
                dataString,
                position.x,
                position.y - 13 / 2 - padding * 4,
              );
        });
      }
    });

    if (chartInstance.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.options.horizontalLine[index];

        if (!line.style) {
          style = '#080808';
        } else {
          style = line.style;
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y);
        } else {
          yValue = 0;
        }

        ctx.lineWidth = 3;

        if (yValue) {
          window.chart = chartInstance;
          ctx.beginPath();
          ctx.moveTo(0, yValue);
          ctx.lineTo(canvas.width, yValue);
          ctx.strokeStyle = style;
          ctx.stroke();
        }

        if (line.text) {
          ctx.fillStyle = style;
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth);
        }
      }
      return;
    }
  },
};
import Chart from 'chart.js';
import { Line } from 'vue-chartjs';
export default {
  extends: Line,
  beforeMount() {
    this.addPlugin(horizonalLinePlugin);
  },
  props: ['data', 'options'],
  mounted() {
    this.renderChart(this.data, this.options);
  },
};
</script>

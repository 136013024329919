<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button
          type="button"
          class="btn_sub1"
          @click="fullScreen = !fullScreen"
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>온습도 현황</h2>
          <span>조회시간 : {{ str_time }}</span>
        </div>
        <swiper
          ref="mySwiperRef"
          class="swiper-container"
          :options="swiperOption"
          @slideChange="key++"
        >
          <swiper-slide
            class="monitor_body"
            v-for="item in grouped_temphumi_logs"
            :key="item.id"
          >
            <div class="day_card">
              <h2 class="curr_time">
                {{ curr_date }}
                <br />{{ curr_weekDay }}
                <span>{{ curr_time }}</span>
              </h2>
            </div>
            <div class="th_card">
              <h5>{{ item.plc_location_name }}</h5>
              <p>
                적정온도
                <span>{{
                  plc_sensor_lists.length > 0
                    ? plc_sensor_lists.find(
                        x =>
                          x.plc_location_id == item.plc_location_id &&
                          x.plc_sensor_detail_type_id == 1,
                      ).min_value +
                      '~' +
                      plc_sensor_lists.find(
                        x =>
                          x.plc_location_id == item.plc_location_id &&
                          x.plc_sensor_detail_type_id == 1,
                      ).max_value
                    : ''
                }}</span>
                ℃
              </p>
              <p>
                적정습도
                <span>{{
                  plc_sensor_lists.length > 0
                    ? plc_sensor_lists.find(
                        x =>
                          x.plc_location_id == item.plc_location_id &&
                          x.plc_sensor_detail_type_id == 2,
                      ).min_value +
                      '~' +
                      plc_sensor_lists.find(
                        x =>
                          x.plc_location_id == item.plc_location_id &&
                          x.plc_sensor_detail_type_id == 2,
                      ).max_value
                    : ''
                }}</span>
                %
              </p>
              <div class="monitor_card">
                <div class="t_status">{{ item.value }}<span>℃</span></div>
                <div class="h_status">{{ item.humi_value }}<span>%</span></div>
              </div>
            </div>
            <div class="monitor_card graph_card">
              <line-chart
                v-if="plc_sensor_logs_today_hour.length > 0"
                class="chart_wrap"
                :width="$screen.width > 1280 ? 1000 : 640"
                :height="$screen.width > 1280 ? 300 : 240"
                :options="
                  chartData.find(
                    x => x.plc_location_name == item.plc_location_name,
                  ).options
                "
                :data="
                  chartData.find(
                    x => x.plc_location_name == item.plc_location_name,
                  ).data
                "
                :key="key"
              ></line-chart>
            </div>
          </swiper-slide>
        </swiper>
        <div class="slide_btn_wrap">
          <button type="button" class="button-prev">
            <i class="fa fa-angle-left"></i>
          </button>
          <button
            type="button"
            class="button-play-stop"
            :class="{ active: playStop }"
            @click.prevent="autoPlay()"
          ></button>
          <button type="button" class="button-next">
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SWIPER_MIXIN from '@/mixins/status_board_swiper_no_loop';
import LineChart from '@/layouts/components/charts/LineChart2';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN, SWIPER_MIXIN],
  components: { LineChart },
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      str_time: null,
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_logs_today_hour: 'getPlcSensorLogTodayHour',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      plc_sensor_lists: 'getPlcSensor',
      plc_sensor_types: 'getSensorTypes',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    grouped_temphumi_logs() {
      let clone = this.lodash.clonedeep(this.plc_tempHumi_logs);
      clone = clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);

      let lists = [];
      let location_id_temp = null;
      let duplicate_count = 2;
      clone.forEach((element, index) => {
        if (index % 2 == 0) {
          if (element.plc_location_id == location_id_temp) {
            element.plc_sensor_type_name =
              element.plc_sensor_type_name + duplicate_count;
            duplicate_count++;
          } else {
            duplicate_count = 2;
          }
          location_id_temp = element.plc_location_id;
          element.humi_value = clone[index + 1].value;
          lists.push(element);
        }
      });
      return lists;
    },
    chartData() {
      if (
        this.plc_sensor_logs_today_hour != undefined &&
        this.plc_sensor_logs_today_hour.length > 0
      ) {
        const clone = this.lodash
          .clonedeep(
            this.plc_sensor_logs_today_hour.filter(
              x => x.plc_sensor_type_id == 1,
            ),
          )
          .filter(
            x =>
              x.plc_location_id < 5 &&
              Number(x.create_time.substr(11, 2)) % 2 == 0,
          );
        const obj = clone.reduce(function(rv, x) {
          (rv[x['plc_location_name']] = rv[x['plc_location_name']] || []).push(
            x,
          );
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          arr.push({
            plc_location_name: k,
            data: {
              labels: obj[k]
                .filter((x, index) => index % 2 == 0)
                .map(x => x.create_time.substr(11, 2) + '시'),
              datasets: [
                {
                  label: '온도',
                  data: obj[k]
                    .filter(x => x.plc_sensor_detail_type_id == 1)
                    .map(x => x.value),
                  fill: false,
                  backgroundColor: '#ef6262',
                  borderColor: '#ef6262',
                  borderWidth: 2,
                },
                {
                  label: '습도',
                  data: obj[k]
                    .filter(x => x.plc_sensor_detail_type_id == 2)
                    .map(x => x.value),
                  fill: false,
                  backgroundColor: '#5984d9',
                  borderColor: '#5984d9',
                  borderWidth: 2,
                },
              ],
            },
            options: {
              legend: {
                display: true,
                position: 'right',
                align: 'start',
                labels: {
                  boxWidth: 12,
                },
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      max: Math.max.apply(
                        null,
                        obj[k].map(x => Math.ceil(x.value + 10)),
                      ),
                      callback: value => {
                        return this.$makeComma(value);
                      },
                    },
                  },
                ],
              },
              elements: {
                line: {
                  tension: 0,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 78,
              hover: {
                mode: 'nearest',
                intersect: true,
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                custom: function(tooltip) {
                  if (!tooltip) return;
                  tooltip.displayColors = false;
                },
                enabled: false,
              },
            },
          });
        }
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      await this.FETCH_NO_SPINNER(
        'FETCH_PLC_SENSOR_LOG_TODAY_HOUR',
        '온습도현황',
      );
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.showSpinner();
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER(
      'FETCH_PLC_SENSOR_LOG_TODAY_HOUR',
      '온습도현황',
    );
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR', '센서 정보');
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        await this.FETCH_NO_SPINNER(
          'FETCH_PLC_SENSOR_LOG_TODAY_HOUR',
          '온습도현황',
        );
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    this.key++;
    this.hideSpinner();
  },
};
</script>

<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>포장기 현황</h2>
          <span>조회시간 : {{ str_time }}</span>
        </div>
        <div class="monitor_body">
          <div class="day_card">
            <h2 class="curr_time">
              {{ curr_date }}
              <br />{{ curr_weekDay }}
              <span>{{ curr_time }}</span>
            </h2>
          </div>
          <div class="package_card" v-for="item in asciiChange" :key="item.id">
            <h2>{{ item.plc_location_name }}</h2>
            <ul>
              <li>
                <span>생산제품</span>
                <p>{{ item.ascii }}</p>
              </li>
              <li>
                <span>생산수량</span>
                <p>
                  {{
                    plc_sensor_logs.find(
                      x =>
                        item.plc_location_name == x.plc_location_name &&
                        x.plc_sensor_name.includes('생산수량'),
                    ).value
                  }}
                  <span> EA</span>
                </p>
              </li>
              <li>
                <span>생산시간</span>
                <p>
                  {{ productTimeValue(item) }}
                </p>
              </li>
            </ul>
          </div>
          <div class="package_card">
            <h2>백미 포대포장기</h2>
            <ul>
              <li>
                <span>생산제품</span>
                <p>
                  {{
                    plc_sensor_logs.find(x => x.plc_sensor_id == 35) !=
                    undefined
                      ? plc_sensor_logs.find(x => x.plc_sensor_id == 35)
                          .value == 1
                        ? '10KG'
                        : plc_sensor_logs.find(x => x.plc_sensor_id == 35)
                            .value == 2
                        ? '15KG'
                        : plc_sensor_logs.find(x => x.plc_sensor_id == 35)
                            .value == 3
                        ? '20KG'
                        : '로드실패'
                      : '로드실패'
                  }}
                </p>
              </li>
              <li>
                <span>생산수량</span>
                <p>
                  {{
                    plc_sensor_logs.find(x => x.plc_sensor_id == 11) !=
                    undefined
                      ? plc_sensor_logs.find(x => x.plc_sensor_id == 11).value
                      : '0'
                  }}
                  <span> EA</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    asciiChange() {
      if (this.plc_sensor_logs.length > 0) {
        let clone = this.lodash.clonedeep(this.plc_sensor_logs);
        let cloneFilter = clone
          .filter(
            x =>
              x.plc_sensor_name.includes('제품명') &&
              (x.plc_location_name == '정맥 회전포장기' ||
                x.plc_location_name == '포장실 회전포장기'),
          )
          .sort((a, b) => a.plc_sensor_name - b.plc_sensor_name)
          .reverse();
        const obj = cloneFilter.reduce(function(rv, x) {
          (rv[x['plc_location_name']] = rv[x['plc_location_name']] || []).push(
            x,
          );
          return rv;
        }, {});

        let arr = [];

        for (let k in obj) {
          if (k != 'null') {
            arr.push({
              plc_location_name: k,
              hex_value: obj[k]
                .map(x => x.hex_value.replace(/00/gi, ''))
                .join('')
                .split(''),
              ascii: '',
            });
          }
        }
        let hex = arr.map(x => x.hex_value);
        hex.map((x, i1) =>
          x.map((y, i2) => {
            i2 % 2 == 0
              ? (arr[i1]['ascii'] += String.fromCharCode(
                  parseInt(x.slice(i2, i2 + 2).join(''), 16),
                ))
              : '';
          }),
        );

        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    productTimeValue(item) {
      if (this.plc_sensor_logs.length > 0) {
        let itemHour = this.$decimalMul(
          this.plc_sensor_logs.find(
            x =>
              item.plc_location_name == x.plc_location_name &&
              x.plc_sensor_name.includes('생산시간(시)'),
          ).value,
          100,
        );
        let itemMin = this.$decimalMul(
          this.plc_sensor_logs.find(
            x =>
              item.plc_location_name == x.plc_location_name &&
              x.plc_sensor_name.includes('생산시간(분)'),
          ).value,
          100,
        );
        let itemSec = this.$decimalMul(
          this.plc_sensor_logs.find(
            x =>
              item.plc_location_name == x.plc_location_name &&
              x.plc_sensor_name.includes('생산시간(시)'),
          ).value,
          100,
        );
        String(itemHour).length < 2 ? (itemHour = `0${itemHour}`) : '';
        String(itemMin).length < 2 ? (itemMin = `0${itemMin}`) : '';
        String(itemSec).length < 2 ? (itemSec = `0${itemSec}`) : '';
        return `${itemHour} : ${itemMin} : ${itemSec}`;
      }
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.timerCount = this.timerMax;
    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
        this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<template>
  <div class="article">
    <div class="tbl_wrap" @scroll="realTimeScroll($event)">
      <button
        type="button"
        @click="scrollTop()"
        v-show="scrollHeight > 2000"
      ></button>
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>조회 날짜</label>
            <div>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="changeStartDate($event)"
              />
              <p>~</p>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="changeEndDate($event)"
              />
            </div>
          </div>
          <button class="btn_sub2" @click="searchLog()">
            검색
          </button>
        </div>
        <div>
          <button class="excel_btn" @click="exportExcel">
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ plc_sensor_logs_day_stack_all.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="5">
                전력 사용현황
              </th>
            </tr>
            <tr>
              <th>조회날짜</th>
              <th style="cursor : pointer;" @click="switchLocation()">
                {{
                  target_index_location != null
                    ? `위치(${group_location_name[target_index_location]})`
                    : '위치'
                }}
              </th>
              <th style="cursor : pointer;" @click="switchName()">
                {{
                  target_index_name != null
                    ? `구분(${group_sensor_name[target_index_name]})`
                    : '구분'
                }}
              </th>
              <th>전력 사용량</th>
              <th>월 누적 사용량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in plc_sensor_logs_day_stack_all" :key="item.id">
              <td>{{ item.input_date }}</td>
              <td>{{ item.plc_location_name }}</td>
              <td>{{ item.plc_sensor_name.substring(0, 2) }}</td>
              <td>{{ item.today_value }}</td>
              <td>{{ item.thismonth_value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyymmdd } from '@/utils/func';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import XLSX from 'xlsx';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      group_location_name: [],
      group_sensor_name: [],
      target_index_location: null,
      target_index_name: null,
      scrollHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_logs_day_stack_allSrc: 'getPlcSensorLogDayStackAll',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      plc_sensors: 'getPlcSensor',
      start_date: 'getStartDateFromPlcSensorLog',
      end_date: 'getEndDateFromPlcSensorLog',
    }),
    plc_sensor_logs_day_stack_all() {
      if (
        this.plc_sensor_logs_day_stack_allSrc.length < 1 ||
        this.plc_sensor_logs.length < 1
      ) {
        return [];
      } else {
        return this.lodash
          .clonedeep(this.plc_sensor_logs_day_stack_allSrc)
          .map(x => {
            const found = this.plc_sensor_logs.find(
              y => y.plc_sensor_id == x.plc_sensor_id,
            );
            if (found != undefined) {
              x.plc_sensor_name = found.plc_sensor_name.substring(0, 2);
              x.plc_location_name = found.plc_location_name;
            } else {
              x.plc_sensor_name = '';
              x.plc_location_name = '';
            }
            return x;
          })
          .filter(
            x =>
              x.plc_sensor_id < 19 &&
              (this.target_index_location == null ||
                this.group_location_name[this.target_index_location] ==
                  x.plc_location_name) &&
              (this.target_index_name == null ||
                this.group_sensor_name[this.target_index_name] ==
                  x.plc_sensor_name),
          );
      }
    },
  },
  methods: {
    switchLocation() {
      if (this.group_location_name.length == 0) {
        return null;
      } else if (
        this.target_index_location ==
        this.group_location_name.length - 1
      ) {
        this.target_index_location = null;
      } else if (this.target_index_location == null) {
        this.target_index_location = 0;
      } else {
        this.target_index_location = this.target_index_location + 1;
      }
    },
    switchName() {
      if (this.group_sensor_name.length == 0) {
        return null;
      } else if (this.target_index_name == this.group_sensor_name.length - 1) {
        this.target_index_name = null;
      } else if (this.target_index_name == null) {
        this.target_index_name = 0;
      } else {
        this.target_index_name = this.target_index_name + 1;
      }
    },
    scrollTop() {
      const scrT = document.querySelector('.tbl_wrap');
      scrT.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // this.visible = this.scrT.scrollTop > 1500 ? true : false;
    },

    realTimeScroll(e) {
      this.scrollHeight = e.target.scrollTop;
    },
    async searchLog() {
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_LOG_DAY_STACK_ALL',
        {
          start: this.start_date,
          end: this.end_date,
        },
        '전력 사용 현황',
      );
      await this.FETCH('FETCH_PLC_SENSOR_LOG_NOW', '센서 현재값');
    },
    changeStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          // this.start_date = e.target.value;
          this.$store.commit('setStartDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },
    changeEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '거래기간 오류',
            `거래기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          // this.end_date = e.target.value;
          this.$store.commit('setEndDateToPlcSensorLog', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(new Date()));
      }
    },
    setGroupProperty() {
      if (this.plc_sensor_logs_day_stack_all.length < 1) {
        this.group_location_name = [];
        this.group_sensor_name = [];
        this.target_index_location = null;
        this.target_index_name = null;
      } else {
        const mapLocationName = this.lodash
          .clonedeep(this.plc_sensor_logs_day_stack_all)
          .map(x => x.plc_location_name);
        const mapSensorName = this.lodash
          .clonedeep(this.plc_sensor_logs_day_stack_all)
          .map(x => x.plc_sensor_name.substring(0, 2));
        const set1 = new Set(mapLocationName);
        const set2 = new Set(mapSensorName);
        this.group_location_name = [...set1];
        this.group_sensor_name = [...set2];
      }
    },
    exportExcel() {
      let powerList = [];
      const date = this.start_date + '~' + this.end_date;
      const locationColumn =
        this.target_index_location != null
          ? `위치(${this.group_location_name[this.target_index_location]})`
          : '위치';
      const location =
        locationColumn === '위치'
          ? '전체'
          : this.group_location_name[this.target_index_location];
      const divisionColumn =
        this.target_index_name != null
          ? `구분(${this.group_sensor_name[this.target_index_name]})`
          : '구분';

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 4 } },
        { s: { r: 6, c: 0 }, e: { r: 6, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        ['위치', location],
        [],
        [],
      ];

      powerList = powerList.concat(standardInfo);

      powerList.push(['전력 사용현황']);
      powerList.push([
        '조회날짜',
        locationColumn,
        divisionColumn,
        '전력 사용량',
        '월 누적 사용량',
      ]);

      this.plc_sensor_logs_day_stack_all.forEach(el => {
        powerList.push([
          el.input_date,
          el.plc_location_name,
          el.plc_sensor_name.substring(0, 2),
          el.today_value,
          el.thismonth_value,
        ]);
      });

      let wb = XLSX.utils.book_new();

      let dataPower = XLSX.utils.json_to_sheet(powerList, { skipHeader: true });

      dataPower['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataPower, '전력 사용현황');

      let title = '전력사용현황_' + location + '(' + date + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  watch: {
    plc_sensor_logs_day_stack_all() {
      if (
        this.target_index_location == null &&
        this.target_index_name == null
      ) {
        this.setGroupProperty();
      }
    },
  },
  async created() {
    // await this.FETCH_WITH_PAYLOAD('FETCH_PLC_SENSOR_LOG_DAY_STACK_ALL',  ,'전력 사용현황');

    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToPlcSensorLog', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToPlcSensorLog', yyyymmdd(date2));
    }
    await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
    this.setGroupProperty();
  },
};
</script>

<template>
  <div
    id="contents"
    :class="{
      plc_status_card: tabIndex <= 2,
      plc_th_status: tabIndex == 0,
      plc_power_status: tabIndex == 1,
      plc_package_status: tabIndex == 2,
      plc_th_log: tabIndex == 3,
      plc_power_log: tabIndex == 4 || tabIndex == 5,
      plc_package_log: tabIndex == 5,
      default_setting: tabIndex == 6,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '온습도 실시간 현황', name: 'plc_th_status' },
            { title: '전력 실시간 사용현황', name: 'plc_power_status' },
            { title: '실시간 포장기 현황', name: 'plc_package_status' },
            { title: '온습도 현황', name: 'plc_th_log' },
            { title: '전력 사용현황', name: 'plc_power_search_status' },
            { title: '포장기 현황', name: 'plc_package_log' },
            { title: '기준값 설정', name: 'default_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">실시간 전력 사용현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">실시간 포장기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">전력 사용현황</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">포장기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 6 }">
            <a @click="SetOpenTabIndex(6)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <temp-real-time-form v-if="tabIndex == 0"></temp-real-time-form>
      <plc-power-status-form v-if="tabIndex == 1"></plc-power-status-form>
      <plc-package-status-form v-if="tabIndex == 2"></plc-package-status-form>
      <temp-log-form v-if="tabIndex == 3"></temp-log-form>
      <plc-power-log-form v-if="tabIndex == 4"></plc-power-log-form>
      <plc-package-log-form v-if="tabIndex == 5"></plc-package-log-form>
      <default-plc-form v-if="tabIndex == 6"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealTimeForm from '@/views/forms/Custom/Monitor/14/TempRealTimeForm';
import TempLogForm from '@/views/forms/Custom/Monitor/14/TempLogForm';
import PlcPowerStatusForm from '@/views/forms/Custom/Monitor/14/PlcPowerStatusForm';
import PlcPackageStatusForm from '@/views/forms/Custom/Monitor/14/PlcPackageStatusForm';
import PlcPowerLogForm from '@/views/forms/Custom/Monitor/14/PlcPowerLogForm';
import PlcPackageLogForm from '@/views/forms/Custom/Monitor/14/PlcPackageLogForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/14/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealTimeForm,
    PlcPowerStatusForm,
    PlcPackageStatusForm,
    PlcPowerLogForm,
    PlcPackageLogForm,
    DefaultPlcForm,
    TempLogForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'saengumdle_monitoring';
</style>
